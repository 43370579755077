import { Button, Grid, Snackbar, TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '../data/config.json';
import type { RootState } from '../store';
import { selector as studentsSelector } from '../store/students/adapter';
import { actions as studentsActions } from '../store/students/reducer';
import { getNextWordsFromHistory } from '../utils/words';

type FormValues = {
  name?: string;
};

export const AddStudent = () => {
  const { students } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<FormValues>({});

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleNameInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;

    setFormValues({
      ...formValues,
      name: value
    });
  };

  const handleAddButton = () => {
    console.log(formValues);
    if (formValues.name !== undefined) {
      const name = formValues.name;
      const current = studentsSelector.selectAll(students);

      if (current.find((student) => student.name == formValues.name) === undefined) {
        dispatch(
          studentsActions.addOne({
            name,
            current: {
              reading: [
                ...getNextWordsFromHistory([], 'reading', 'easy', config.wordCount.easy),
                ...getNextWordsFromHistory([], 'reading', 'hard', config.wordCount.hard)
              ],
              spelling: [
                ...getNextWordsFromHistory([], 'spelling', 'easy', config.wordCount.easy),
                ...getNextWordsFromHistory([], 'spelling', 'hard', config.wordCount.hard)
              ]
            },
            assessments: []
          })
        );

        setNotificationMessage('Student added');
        setNotificationVisible(true);
      }
    }
  };

  const handleNotificationClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationVisible(false);
    setNotificationMessage('');
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            id="outlined-basic"
            label="Student name"
            variant="outlined"
            style={{ width: '100%' }}
            onChange={handleNameInputChange}
          />
        </Grid>
        <Grid item xs={8}>
          <Button variant="outlined" color="success" onClick={handleAddButton}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={notificationVisible}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
        message={notificationMessage}
      />
    </>
  );
};
