import data from '../data/words.json';
import type { Assessment, AssessmentType } from '../types/assessment';
import type { WordTier } from '../types/student';

export const getNextWordsFromLast = (last: Assessment, tier: WordTier, count: number) => {
  // did not answer correct, re-use
  let nextList = last.results.filter((result) => !result.success && result.tier === tier).map((result) => result.word);
  const newCount = count - nextList.length;

  // idx of furthest word student has reached
  const furthestIdx = Math.max(
    ...last.results.map((result) => data.lists[tier].words.findIndex((value) => value === result.word))
  );

  for (let i = 0; i < newCount; ++i) {
    const add = data.lists[tier].words.at(furthestIdx + i + 1);

    if (add !== undefined) {
      nextList = [...nextList, add];
    }
  }

  console.log(nextList);

  // we must have reached the end of the list, add in values from the start
  const newLapCount = count - nextList.length;
  for (let i = 0; i < newLapCount; ++i) {
    // check that we do not have words from the start
    for (let j = 0; j < data.lists[tier].words.length; ++j) {
      const candidate = data.lists[tier].words.at(j);
      console.log(`i: ${i}, j: ${j}, candidate: ${candidate}`);

      if (candidate) {
        const found = nextList.find((word) => word === candidate);

        // candidate was not found in our list, add and break
        if (!found) {
          console.log('candidate not found, adding to list');
          nextList = [...nextList, candidate];
          break;
        }
      }
    }
  }

  return nextList.map((word) => ({ word, tier }));
};

export const getNextWordsFromHistory = (
  assessments: Assessment[],
  type: AssessmentType,
  tier: WordTier,
  count: number
) => {
  // no assessments done, return count
  if (assessments.length === 0) {
    return data.lists[tier].words.slice(0, count).map((word) => ({ word, tier }));
  }

  const last = [...assessments].reverse().find((assessment) => assessment.type === type);

  // no assessment of type done, return count
  if (last === undefined) {
    return data.lists[tier].words.slice(0, count).map((word) => ({ word, tier }));
  }

  return getNextWordsFromLast(last, tier, count);
};
