import { Container } from '@mui/material';
import { deepPurple, green, pink, red } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';

import { Header } from '../components/Header';
import { AddStudent } from './AddStudent';
import { Student } from './Student';
import { Students } from './Students';

export const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: deepPurple[200]
      },
      secondary: {
        main: pink[200]
      },
      success: {
        main: green[200]
      },
      error: {
        main: red[200]
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header title="High Frequency Words Helper" />
      <Container>
        <Routes>
          <Route path="/" element={<Students type="spelling" />} />
          <Route path="/spelling" element={<Students type="spelling" />} />
          <Route path="/reading" element={<Students type="reading" />} />
          <Route path="/students/add" element={<AddStudent />} />
          <Route path="/students/:name" element={<Student />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
};
