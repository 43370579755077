import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import type { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useCopyToClipboard } from 'react-use';

import type { RootState } from '../store';
import { selector as studentsSelector } from '../store/students/adapter';
import type { AssessmentData, AssessmentType } from '../types/assessment';

type Props = {
  name: string;
  type: AssessmentType;
  assess?: boolean;
  data?: AssessmentData;
  callback?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const StudentCurrentWords = (props: Props) => {
  const { name, type, assess, data, callback } = props;
  const state = useSelector((state: RootState) => state);
  const student = studentsSelector.selectById(state.students, name);
  const [_, copyToClipboard] = useCopyToClipboard();

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          {student?.current[type]
            .filter((value) => value.tier === 'easy')
            .map((value) => (
              <Grid key={value.word} item>
                <Card variant="outlined">
                  <CardContent
                    sx={{
                      height: 100,
                      width: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      verticalAlign: 'middle'
                    }}
                  >
                    <Typography variant="h6">{value.word}</Typography>
                  </CardContent>
                  <CardActions sx={{ height: '58px' }}>
                    {assess === undefined || assess === false ? (
                      <IconButton onClick={() => copyToClipboard(value.word)}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="success"
                            checked={data?.[value.word] ?? false}
                            name={value.word ?? 'invalid'}
                            onChange={callback}
                          />
                        }
                        label="Correct"
                      />
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          {student?.current[type]
            .filter((value) => value.tier === 'hard')
            .map((value) => (
              <Grid key={value.word} item>
                <Card variant="outlined">
                  <CardContent
                    sx={{
                      height: 100,
                      width: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      verticalAlign: 'middle'
                    }}
                  >
                    <Typography variant="h6">{value.word}</Typography>
                  </CardContent>
                  <CardActions sx={{ height: '58px' }}>
                    {assess === undefined || assess === false ? (
                      <IconButton onClick={() => copyToClipboard(value.word)}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="success"
                            checked={data?.[value.word] ?? false}
                            name={value.word ?? 'invalid'}
                            onChange={callback}
                          />
                        }
                        label="Correct"
                      />
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
