import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import type { PickersDayProps } from '@mui/lab/PickersDay';
import PickersDay from '@mui/lab/PickersDay';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { endOfWeek, isSameDay, isWithinInterval, startOfWeek } from 'date-fns';
import type { ComponentType } from 'react';

type Props = {
  value: Date | null;
  callback: React.Dispatch<React.SetStateAction<Date | null>>;
};

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    'borderRadius': 0,
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
})) as ComponentType<CustomPickerDayProps>;

export const WeekPicker = (props: Props) => {
  const { value, callback } = props;

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(value);
    const end = endOfWeek(value);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Select date"
        value={value}
        onChange={(value) => callback(value)}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} style={{ width: '100%' }} />}
      />
    </LocalizationProvider>
  );
};
