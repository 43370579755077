import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';

import type { Assessment } from '../types/assessment';

type Props = {
  data: Assessment;
};

export const StudentPastWords = (props: Props) => {
  const { data } = props;
  const theme = useTheme();

  const getBgColor = (success: boolean) => {
    if (success) {
      return theme.palette.success.main;
    }

    return theme.palette.error.main;
  };

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
          {format(fromUnixTime(data.date), 'do MMMM yyyy')} - {data.type}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          {data.results.map((value) => (
            <Grid key={value.word} item>
              <Card>
                <CardContent
                  sx={{
                    bgcolor: getBgColor(value.success),
                    height: 100,
                    width: 200,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                  }}
                >
                  <Typography variant="h6">{value.word}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
