import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { StudentPastWords } from '../components/StudentPastWords';
import type { RootState } from '../store';
import { selector as studentsSelector } from '../store/students/adapter';
import { actions as studentsActions } from '../store/students/reducer';

export const Student = () => {
  const { name } = useParams<'name'>();
  const theme = useTheme();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const student = studentsSelector.selectById(state.students, name ?? '');

  const [rollbackPromptVisible, setRollbackPromptVisible] = useState(false);
  const [removeStudentPromptVisible, setRemoveStudentPromptVisible] = useState(false);

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const rollbackLastAssessment = useCallback(() => {
    if (student !== undefined) {
      const lastAssessment = [...student.assessments].sort((a, b) => b.date - a.date).at(0);

      if (lastAssessment !== undefined) {
        const lastAssessmentWords = lastAssessment.results.map((result) => result.word);
        const newAssessments = student.assessments.filter((assessment) => assessment.date !== lastAssessment.date);

        dispatch(
          studentsActions.updateOne({
            id: student.name,
            changes: {
              current: {
                ...student.current,
                [lastAssessment.type]: lastAssessmentWords
              },
              assessments: newAssessments
            }
          })
        );
      }
    }
  }, [dispatch, student]);

  const removeStudent = useCallback(() => {
    if (student !== undefined) {
      dispatch(studentsActions.removeOne(student.name));
    }
  }, [dispatch, student]);

  const handleRemoveStudent = () => {
    removeStudent();
    handleRemoveStudentPromptClose();

    setNotificationMessage('Removed student');
    setNotificationVisible(true);
  };

  const handleRemoveStudentPromptOpen = () => {
    setRemoveStudentPromptVisible(true);
  };

  const handleRemoveStudentPromptClose = () => {
    setRemoveStudentPromptVisible(false);
  };

  const handleRollback = () => {
    rollbackLastAssessment();
    handleRollbackPromptClose();

    setNotificationMessage('Rolled back last assessment');
    setNotificationVisible(true);
  };

  const handleRollbackPromptOpen = () => {
    setRollbackPromptVisible(true);
  };

  const handleRollbackPromptClose = () => {
    setRollbackPromptVisible(false);
  };

  const handleNotificationClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationVisible(false);
    setNotificationMessage('');
  };

  return (
    <>
      {student === undefined && <Typography variant="h4">Unknown student</Typography>}
      {student !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="h4">{name}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                height: '70px',
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                textAlign: 'right',
                verticalAlign: 'right'
              }}
            >
              <Tooltip title="Remove student">
                <IconButton>
                  <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
                    <DeleteIcon onClick={handleRemoveStudentPromptOpen} />
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5">Past Assessments</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                height: '70px',
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                textAlign: 'right',
                verticalAlign: 'right'
              }}
            >
              {student.assessments.length > 0 && (
                <Tooltip title="Rollback last assessment">
                  <IconButton>
                    <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
                      <UndoIcon onClick={handleRollbackPromptOpen} />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={12}>
              <List>
                {[...student?.assessments]
                  .sort((a, b) => b.date - a.date)
                  .map((assessment) => (
                    <ListItem key={assessment.date} divider>
                      <StudentPastWords data={assessment} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Grid>
          <Dialog open={removeStudentPromptVisible} onClose={handleRemoveStudentPromptClose}>
            <DialogTitle>Remove student?</DialogTitle>
            <DialogContent>
              <DialogContentText>Removing a student will delete all of their progress. Are you sure?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemoveStudentPromptClose}>No</Button>
              <Button onClick={handleRemoveStudent}>Yes</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={rollbackPromptVisible} onClose={handleRollbackPromptClose}>
            <DialogTitle>Rollback assessment?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Rolling back will delete the last assessment and update the student&apos;s current words, are you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRollbackPromptClose}>No</Button>
              <Button onClick={handleRollback}>Yes</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Snackbar
        open={notificationVisible}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
        message={notificationMessage}
      />
    </>
  );
};
