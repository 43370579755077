import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { adapter } from './adapter';

const { addOne, removeOne, setAll, updateOne } = adapter;

const slice = createSlice({
  name: 'students',
  initialState: adapter.getInitialState(),
  reducers: {
    addOne,
    removeOne,
    updateOne,
    setAll
  }
});

export const reducer = persistReducer({ key: slice.name, storage }, slice.reducer);
export const { actions } = slice;
