import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { reducer as studentsReducer } from './store/students/reducer';

const rootReducer = combineReducers({
  students: studentsReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
      immutableCheck: true
    }),
  devTools: process.env.NODE_ENV === 'development'
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
