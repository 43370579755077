import FileUploadIcon from '@mui/icons-material/FileUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import type { RootState } from '../store';
import { selector as studentsSelector } from '../store/students/adapter';
import { actions as studentsActions } from '../store/students/reducer';

type Props = {
  title: string;
};

export const Header = (props: Props) => {
  const { title } = props;
  const dispatch = useDispatch();
  const { students } = useSelector((state: RootState) => state);

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [importPromptVisible, setImportPromptVisible] = useState(false);

  const handleExport = () => {
    const data = new Blob([JSON.stringify(studentsSelector.selectAll(students))], { type: 'application/json' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(data);
    element.download = 'high-frequency-words-data.json';
    element.hidden = true;
    document.body.appendChild(element);
    element.click();
  };

  const handleImport = () => {
    setImportPromptVisible(false);

    const element = document.createElement('input');
    element.type = 'file';
    element.multiple = false;
    element.hidden = true;
    element.onchange = importData.bind(this);
    document.body.appendChild(element);
    element.click();
  };

  const handleImportPromptOpen = () => {
    setImportPromptVisible(true);
  };

  const handleImportPromptClose = () => {
    setImportPromptVisible(false);
  };

  const handleNotificationClose = () => {
    setNotificationVisible(false);
  };

  const importData = async (e: Event) => {
    const target = (e as InputEvent).target as HTMLInputElement;
    const file = (target?.files as FileList)[0];

    if (file !== null) {
      if (!file.name.endsWith('.json')) {
        setNotificationMessage('Unable to import data');
        setNotificationVisible(true);
        return;
      }

      try {
        const data = JSON.parse(await file.text());
        dispatch(studentsActions.setAll(data));

        setNotificationMessage('Imported data successfully');
        setNotificationVisible(true);
      } catch {
        setNotificationMessage('Unable to import data');
        setNotificationVisible(true);
      }
    }
  };

  return (
    <>
      <AppBar position="static" style={{ marginBottom: '10px' }}>
        <Toolbar>
          <Typography
            variant="h5"
            color="inherit"
            component={RouterLink}
            to="/"
            sx={{ flexGrow: 1, textDecoration: 'none' }}
          >
            {title}
          </Typography>
          <IconButton color="inherit" component={RouterLink} to="/spelling">
            Spelling
          </IconButton>
          <IconButton color="inherit" component={RouterLink} to="/reading">
            Reading
          </IconButton>
          <Tooltip title="Add new student">
            <IconButton color="inherit" component={RouterLink} to="/students/add">
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save data">
            <IconButton color="inherit" onClick={handleExport}>
              <SaveAsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Import data" onClick={handleImportPromptOpen}>
            <IconButton color="inherit">
              <FileUploadIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Dialog open={importPromptVisible} onClose={handleImportPromptClose}>
        <DialogTitle>Import data?</DialogTitle>
        <DialogContent>
          <DialogContentText>Importing data will overwrite all existing data. Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImportPromptClose}>No</Button>
          <Button onClick={handleImport}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={notificationVisible}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
        message={notificationMessage}
      />
    </>
  );
};
