import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Avatar,
  Button,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { fromUnixTime, getUnixTime, isSameWeek } from 'date-fns';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { StudentCurrentWords } from '../components/StudentCurrentWords';
import { WeekPicker } from '../components/WeekPicker';
import config from '../data/config.json';
import type { RootState } from '../store';
import { selector as studentsSelector } from '../store/students/adapter';
import { actions as studentsActions } from '../store/students/reducer';
import type { AssessmentData, AssessmentResult, AssessmentType } from '../types/assessment';
import type { Student } from '../types/student';
import { getNextWordsFromLast } from '../utils/words';

type Props = {
  type: AssessmentType;
};

export const Students = (props: Props) => {
  const { type } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const state = useSelector((state: RootState) => state);
  const students = studentsSelector.selectAll(state.students);

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [assessmentData, setAssessmentData] = useState<AssessmentData>({});
  const [dateValue, setDateValue] = useState<Date | null>(null);

  const enableAssessing = (student: Student | null = null) => {
    setSelectedStudent(student);
  };

  const saveAssessment = (student: Student) => {
    enableAssessing();
    setAssessmentData({});
    setDateValue(null);

    // add checkbox values to current words
    const assessedWords = student.current[type].map<AssessmentResult>((result) => {
      // checkbox was used
      if (assessmentData[result.word] !== undefined) {
        return {
          word: result.word,
          tier: result.tier,
          success: assessmentData[result.word],
          carried: false
        };
      }

      return {
        word: result.word,
        tier: result.tier,
        success: false,
        carried: false
      };
    });

    const newAssessment = {
      date: getUnixTime(dateValue ?? new Date()),
      type,
      results: assessedWords
    };

    dispatch(
      studentsActions.updateOne({
        id: student.name,
        changes: {
          assessments: [...student.assessments, newAssessment]
        }
      })
    );

    dispatch(
      studentsActions.updateOne({
        id: student.name,
        changes: {
          current: {
            ...student.current,
            [type]: [
              ...getNextWordsFromLast(newAssessment, 'easy', config.wordCount.easy),
              ...getNextWordsFromLast(newAssessment, 'hard', config.wordCount.hard)
            ]
          }
        }
      })
    );

    setNotificationMessage('Assessment saved');
    setNotificationVisible(true);
  };

  const checkboxChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setAssessmentData({
      ...assessmentData,
      [event.target.name]: event.target.checked
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isDateDisabled = (date: Date) => {
    if (selectedStudent !== null) {
      // get list of dates from assessment history
      const oldDates = selectedStudent.assessments.map((assessment) => fromUnixTime(assessment.date));

      if (oldDates.some((oldDate) => isSameWeek(date, oldDate))) {
        return true;
      }
    }

    return false;
  };

  const handleNotificationClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationVisible(false);
    setNotificationMessage('');
  };

  // reset assessing on page change
  useEffect(() => {
    enableAssessing();
  }, [props.type]);

  return (
    <>
      <FormControl>
        <FormGroup>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
                {type}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {students.map((student) => (
                  <ListItem key={student.name}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <ListItemButton component={RouterLink} to={`/students/${student.name}`}>
                          <ListItemText primaryTypographyProps={{ fontSize: '1.5rem' }}>{student.name}</ListItemText>
                        </ListItemButton>
                      </Grid>
                      {selectedStudent === null && (
                        <Grid
                          item
                          xs={6}
                          sx={{
                            height: '70px',
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                            textAlign: 'right',
                            verticalAlign: 'right'
                          }}
                        >
                          <Tooltip title="Add assessment">
                            <IconButton onClick={() => enableAssessing(student)}>
                              <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
                                <EditIcon />
                              </Avatar>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      {selectedStudent !== null && selectedStudent.name === student.name && (
                        <>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              height: '70px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              verticalAlign: 'middle'
                            }}
                          >
                            <Button disabled={dateValue === null} onClick={() => saveAssessment(student)}>
                              Save assessment
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <WeekPicker value={dateValue} callback={setDateValue} />
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            sx={{
                              height: '70px',
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'right',
                              textAlign: 'right',
                              verticalAlign: 'right'
                            }}
                          >
                            <Tooltip title="Cancel assessment">
                              <IconButton onClick={() => enableAssessing(null)}>
                                <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
                                  <CloseIcon />
                                </Avatar>
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <StudentCurrentWords
                          name={student.name}
                          type={type}
                          assess={selectedStudent !== null && selectedStudent.name === student.name}
                          data={assessmentData}
                          callback={checkboxChangeHandler}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
      <Snackbar
        open={notificationVisible}
        autoHideDuration={3000}
        onClose={handleNotificationClose}
        message={notificationMessage}
      />
    </>
  );
};
